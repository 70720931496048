<template>
  <v-sheet class="text-center">
    <div class="text-caption text-right">
      <span v-if="processDirection == 'Input'">{{$vuetify.lang.t('$vuetify.worksheet.in')}}</span>
      <span v-if="processDirection == 'Output'">{{$vuetify.lang.t('$vuetify.worksheet.out')}}</span>
    </div>
    <div
      class="d-flex justify-center my-5"
      :style="`font-size: ${headerFontSize}px`"
    >
      <div v-if="processType">
        {{ processType.name }}
      </div>
      <v-divider v-if="product && processType" vertical class="mx-3" />
      <div v-if="product">
        {{ product.name }}
      </div>
      <v-divider v-if="location" vertical class="mx-3" />
      <div v-if="location">
        {{ location.name }}
      </div>
    </div>
    <qriously :value="qrCodeUrl" :size="qrSize" />
    <div :style="`font-size: ${memoFontSize}px`" v-if="memo">
      {{ memo }}
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { parseDomain } from "parse-domain";

export default {
  props: {
    qrCode: {
      type: Object,
      required: true,
    },
    qrSize: {
      type: Number,
      required: false,
      default: 100,
    },
    headerFontSize: {
      type: Number,
      required: false,
      default: 12,
    },
    memoFontSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data: () => {
    return {
      worksheetTypeId: null,
      url: null,
      name: null,
      processType: null,
      processDirection: null,
      location: null,
      product: null,
      memo: null,
      domainUrl: null,
      entryPhoto: true,
      generated: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("locations", ["locations"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    qrCodeUrl() {
      if(this.qrCode && this.qrCode.uuid){
        return `${this.domainUrl}/qr-dispatch/${this.qrCode.uuid}/`;
      }
      else{ return null }
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheetTypeId
        );
      } else {
        return {};
      }
    },
  },
  async mounted() {
    await this.fetchWorksheetTypeDefinitions();
    await this.initWorksheet();

    const parseResult = parseDomain(window.location.hostname);
    const { subDomains, domain, topLevelDomains } = parseResult;

    if (domain) {
      const subDomain =
        subDomains && subDomains.length > 0 ? subDomains[0] : "factory";
      const topLevel = topLevelDomains.join(".");
      this.domainUrl = `https://${subDomain}.${domain}.${topLevel}/#`;
    } else {
      this.domainUrl = `http://localhost:${process.env.VUE_APP_PORT}/#`;
    }

    this.$emit('input', this.qrCodeUrl);
  },
  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("locations", ["loadLocations"]),
    ...mapActions("messages", ["addMessage", "addErrorMessage"]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    async initWorksheet() {
      if (this.qrCode) {
        this.id = this.qrCode.id;
        const steps = JSON.parse(this.qrCode.jsonData);
        const postStep = steps[0];
        this.worksheetTypeId = postStep.type;
        if (postStep.params) {
          if (this.worksheetTypeFields.hasProcessType) {
            const processTypeId = postStep.params.processTypeId;
            this.processType = await this.loadCategory(processTypeId);
          }
          if (this.worksheetTypeFields.hasLocation) {
            const locationId = postStep.params.locationId;
            this.location = await this.loadCategory(locationId);
          }
          if (this.worksheetTypeFields.hasProduct) {
            const productId = postStep.params.productId;
            this.product = await this.loadCategory(productId);
          }
          this.memo = postStep.params.memo;
          this.processDirection = postStep.params.processDirection;

        }
      }
    },
    async loadCategory(id) {
      if (id) {
        return await this.fetchCategory(id)
          .then((category) => {
            return category;
          })
          .catch((error) => {
            this.addErrorMessage("Item not found; " + error.data.message);
            return null;
          });
      }
    },
  },
};
</script>

<style></style>
